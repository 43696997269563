<template>
    <div :key="data.account_number + '-' + transaction">
        <a class="dropdown-item" v-b-modal="data.account_number + transaction">Internal (P to P)</a>
        <b-modal :id="data.account_number + transaction" v-model="modalShow" size="xl" @show="resetModal"
            @hidden="resetModal" @ok="submitAccountTransfer">
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>{{ transaction }} <span class="ms-5"><i class="far fa-money-bill-alt"></i></span></h5>
                <b-button variant="white" @click="close()">
                    &times;
                </b-button>
            </template>
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">

                    <div class="col-md-6">
                        <b-form-group label-for="account_number" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="account_number">Source Account Number</label>
                            </template>
                            <b-form-input id="account_number" v-model="data.account_number"
                                v-validate="{ required: false }" :state="validateState('account_number')"
                                name="account_number" class="mt-2 field-container fields" type="text"
                                disabled></b-form-input>
                            <b-form-invalid-feedback>
                                The Balance field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.account_balance">
                                   {{ form.errors.account_balance[0] }}
                               </errors> -->
                        </b-form-group>
                    </div>

                    <div class="col-md-6">
                        <b-form-group label-for="member" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="member">Recipient Member Code/Mobile <star>
                                    </star></label>
                            </template>
                            <b-form-input id="member" v-model="form.code" @input="onInput" name="member"
                                class="mt-2 field-container fields" type="text"
                                placeholder="Eg. Old Code, New Code, Mobile #, First name, Last name"></b-form-input>
                            <!-- <b-form-invalid-feedback >
                               The Balance field is required
                           </b-form-invalid-feedback> -->
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label-for="member" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="member">Source Member Name</label>
                            </template>
                            <b-form-input id="member" v-model="getFullName" v-validate="{ required: false }"
                                :state="validateState('member')" name="member" class="mt-2 field-container fields"
                                type="text" disabled></b-form-input>
                            <b-form-invalid-feedback>
                                The Balance field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <!-- <div class="col-md-6">
                       <b-form-group
                           label-for="teller"
                           class="mb-3">
                           <template #label>
                               <label class="akkurate-dark small" for="teller">Teller</label>
                           </template>
                           <b-form-input
                               id="teller"
                               name="teller"
                               class="mt-2 field-container fields"
                               v-validate="{ required: false }"
                               :state="validateState('teller')"
                               type="text"
                               disabled
                           ></b-form-input>
                           <b-form-invalid-feedback >
                               The Balance field is required
                           </b-form-invalid-feedback>
                       </b-form-group>
                   </div> -->


                    <div class="col-12 col-md-6">
                        <b-form-group label-for="customer_name" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="customer_name">Recipient Member Name</label>
                            </template>
                            <b-form-input id="customer_name" v-validate="{ required: true }" name="customer_name"
                                class="mt-2 field-container fields" type="text" v-model="form.customer_name"
                                disabled></b-form-input>
                        </b-form-group>
                    </div>



                    <div class="col-md-6">
                        <b-form-group label-for="member" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="member">Source Product Name</label>
                            </template>
                            <b-form-input id="member" v-model="data.account_type.name" v-validate="{ required: false }"
                                :state="validateState('member')" name="member" class="mt-2 field-container fields"
                                type="text" disabled></b-form-input>
                            <b-form-invalid-feedback>
                                The Balance field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>


                    <!-- recipient product here -->

                    <div class="col-md-6">
                        <b-form-group label-for="teller" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="teller">Recipient Product Name <star></star>
                                    </label>
                            </template>
                            <multiselect class="mt-2 field-container" v-model="form.product" :options="products"
                                label="name" selectLabel="" @select="accountSelected" deselectLabel="">
                            </multiselect>
                            <b-form-invalid-feedback>
                                The Balance field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.account_balance">
                                   {{ form.errors.account_balance[0] }}
                               </errors> -->
                        </b-form-group>
                    </div>


                    <div class="col-12 col-md-6">
                        <b-form-group label-for="customer_mobile" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="customer_mobile">Source Mobile</label>
                            </template>
                            <b-form-input id="customer_mobile" name="customer_mobile"
                                class="mt-2 field-container fields" type="text" v-model="data.customer.mobile"
                                disabled></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-6">
                        <b-form-group label-for="customer_mobile" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="customer_mobile">Recipient Mobile</label>
                            </template>
                            <b-form-input id="customer_mobile" name="customer_mobile"
                                class="mt-2 field-container fields" type="text" v-model="form.customer_mobile"
                                disabled></b-form-input>
                        </b-form-group>
                    </div>


                    <div class="col-md-6">
                        <b-form-group label-for="amount" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="amount">Amount <star></star></label>
                            </template>
                            <b-form-input id="amount" v-validate="{ required: true }"
                                :state="validateState('form.trans_amount')" name="amount"
                                class="mt-2 field-container fields" type="text"
                                v-model="form.trans_amount"></b-form-input>
                            <b-form-invalid-feedback>
                                The Amount field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.account_balance">
                                   {{ form.errors.account_balance[0] }}
                               </errors> -->
                        </b-form-group>
                    </div>

                    <!-- date here -->

                    <div class="col-12 col-md-6">
                        <label class="akkurate-dark small" for="trans_date">Transaction Date <star></star></label>
                        <b-form-group id="trans_date" label-for="trans_date">
                            <b-form-input class="mt-2 field-container fields" v-validate="{ required: true }"
                                :state="validateState('form.trans_date')" name="trans_date" v-model="form.trans_date"
                                type="date"></b-form-input>
                            <b-form-invalid-feedback>
                                The transaction date field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.date_of_birth">
                            {{ form.errors.date_of_birth[0] }}
                        </errors> -->
                        </b-form-group>
                    </div>

                    <!-- <div class="col-12 col-md-6">
                   </div> -->
                    <div class="col-12 col-md-6">
                        <b-form-group label-for="narration" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="narration">Narration <star></star></label>
                            </template>
                            <b-form-textarea class="mt-2 fields" id="narration" v-validate="{ required: true }"
                                :state="validateState('narration')" name="narration" v-model="form.narration"
                                placeholder="Enter Narration..."></b-form-textarea>
                            <b-form-invalid-feedback>
                                The Narration field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>



                <hr />
                <div class="row">
                    <div class="col-12 col-md-12 text-center">
                        <label class="label">
                            <input type="file" @change="handleFileUpload" />
                            <span>Form of Identification</span>
                        </label>
                        <div v-if="selectedFile">
                            <label class="akkurate-dark small">Preview</label>
                            <img class="imagePreviewWrapper" :src="previewUrl" alt="File Preview" />
                            <b-button type="button" @click="uploadFile" :disabled="upload_processing"
                                class="akkurate-green-btn">
                                <b-spinner v-if="spin_visible" small></b-spinner>
                                <i v-if="success_visible" class="fas fa-check loading"></i>
                                <i v-if="text_visible" class="fas fa-upload loading"></i> {{ upload }}
                            </b-button>

                        </div>
                    </div>
                </div>
            </form>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="$bvModal.hide(data.account_number + transaction)" type="button"
                        class="akkurate-grey-btn modal-btn-width ms-2 float-right"><span
                            class="small"><font-awesome-icon :icon="['fas', 'times']" /> Cancel</span></b-button>
                    <b-button @click="ok()" type="submit" class="akkurate-green-btn modal-btn-width float-right"><span
                            class="small"><font-awesome-icon :icon="['fas', 'save']" /> Save</span></b-button>
                </div>
            </template>
        </b-modal>
        <internal-deposit :report_data="report_data" :showModal="showDeposit"
            @closeModal="closeModal"></internal-deposit>
        <!-- <cheque-withdrawal :report_data="report_data" :showModal="showWithdrawal" @closeModal="closeModal"></cheque-withdrawal> -->

    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import InternalDeposit from '../../reports/transfers/InternalDeposit.vue'
export default {
    name: "Internal",
    components: {
        Multiselect,
        InternalDeposit
        // ChequeDeposit,
        // ChequeWithdrawal,
    },
    props: ["data", "transaction"],
    data() {
        return {
            selectedFile: null,
            previewUrl: null,
            status_options: ['Active', 'Inactive'],
            isSaving: false,
            add_or_edit: "add",
            is_processing: false,
            spin_visible: false,
            text_visible: true,
            success_visible: false,
            upload_processing: false,
            upload: "Upload",
            // trasaction_date: null,
            amount: "",
            narration: "",
            report_data: "",
            customer_no: "",
            customer_name: "",
            transaction_id: "",
            bank_chart: "",
            bank_chart_options: [],
            modalShow: false,
            // full_name: this.data.customer.first_name + ' ' + this.data.customer.last_name,
            showDeposit: false,
            // showWithdrawal: false,

            debounceTimer: null,
            form: {
                code: "",
                account_id: "",
                account_number: "",
                // sender_name: "",
                recipient_name: "",
                product_name: "",
                recipient_account_id: "",
                trans_amount: null,
                source: "Web",
                network: null,
                narration: null,
                transaction_mode: "Transfer",
                form_of_id_type: null,
                trans_date: "",
                currency: "GHS",
                teller: "",
                // transcation_date: "",
                // amount: "",
                customer_no: "",
                customer_name: "",
                customer_mobile: "",
            },
            products: []
        }
    },
    computed: {
        getFullName() {
            if (this.modalShow == true) {
                return this.data?.customer?.first_name + ' ' + this.data?.customer?.last_name
            }
        }
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
                this.previewFile(file);
            }
        },
        previewFile(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.previewUrl = reader.result;
            };
        },
        closeModal() {
            this.showDeposit = false;
            // this.showWithdrawal = false;
        },
        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async uploadFile() {
            this.upload = "Processing..."
            this.spin_visible = true;
            this.text_visible = false;
            await this.timeout(3000);
            this.spin_visible = false;
            this.text_visible = false;
            this.success_visible = true
            this.upload_processing = true
            this.upload = "Successfully Uploaded"
            // console.log(this.data.account_number);
        },
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        resetModal() {
            this.form.trans_date = ''
            this.form.trans_amount = ''
            this.form.narration = ''
        },
        submitAccountTransfer(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit()
        },
        onInput(input) {
            if (input) {
                clearTimeout(this.debounceTimer);
                this.debounceTimer = setTimeout(this.fetchProducts, 1000);
            }
            else {
                this.products = [];
            }
        },
        async fetchProducts() {
            await ApiService.post("/accounts/paginate", {
                page: 1,
                per_page: 10,
                // filter: filter,
                // branch_id: "",
                account_type_id: "",
                // account_number: ac,
                phone_number: this.form.code,
                status: "", //Inactive,	Active, Dormant, Closed
            })
                .then((response) => {
                    if (response.data.data.length > 0) {
                        this.form.customer_name = response.data?.data[0]?.customer?.first_name + " " + response.data?.data[0]?.customer?.last_name;
                        this.form.customer_mobile = response.data?.data[0]?.customer.mobile;
                        this.products = response.data.data.map((index) => {
                            return {
                                id: index.id,
                                product_name: index?.account_type?.name,
                                name: index.account_number + " - " + index?.account_type?.name + " - " + index?.customer?.first_name + " " + index?.customer?.last_name,
                                product_type: index?.account_type?.name,
                                account_number: index?.account_number,
                                customer_name: index?.customer?.first_name + " " + index?.customer?.last_name,
                                customer_mobile: index?.customer?.mobile,
                            };
                        });
                    } else {
                        this.products = [];
                        // swal.fire({
                        //   icon: "error",
                        //   title: "Not Found",
                        //   text: "This account was not found please try again!!!",
                        //   showCancelButton: false, // Show the "Cancel" button
                        //   confirmButtonText: "OK", // Customize the text for the "OK" button
                        //   buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        //   customClass: {
                        //     confirmButton:
                        //       "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
                        //   },
                        //   // footer: '<a href="#">Why do I have this issue?</a>'
                        // });
                    }
                    //   this.is_searching = false;
                    //   this.search_btn = "Search";
                })
                .catch((error) => {
                    //   this.is_searching = false;
                    //   this.search_btn = "Search";
                });
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(result => {
                if (!result) {
                    // console.log(result);
                    return;
                }
                this.isSaving = true;
                this.form.account_id = this.data?.id;
                this.form.recipient_account_id = this.form?.product?.id
                // this.form.recipient_account_id = 
                console.log(this.data)

                swal.fire({
                    title: "Please confirm!!!",
                    html: `Are you sure you want to transfer\n<strong>GHS ${this.form.trans_amount}</strong>` +
                        ` from <b>${this.getFullName}</b> - <b>${this.data?.account_type?.name}</b> to <b>${this.form.customer_name} - ${this.form.product_name}</b> ?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    // confirmButtonText: "Ok",
                    buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                    customClass: {
                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                    },

                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {

                        ApiService.post("/transactions/withdrawals/transfer", this.form)
                            .then((response) => {
                                this.isSaving = false;

                                swal.fire({
                                    title: 'Transfer Successfully Processed',
                                    // text: 'References No: 58518111476',
                                    confirmButtonColor: "#018673",
                                    icon: 'success',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    showCancelButton: true, // Show the "Cancel" button
                                    confirmButtonText: 'OK', // Customize the text for the "OK" button
                                    cancelButtonText: 'Print', // Text for the custom button
                                    buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                    customClass: {
                                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                        cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                                    }
                                }).then((result) => {
                                    // this.fetchProducts()

                                    this.$emit('reloadAccounts')
                                    if (result.isConfirmed) {
                                        this.$bvModal.hide(this.data.account_number + this.transaction);
                                    } else if (result.isDismissed) {
                                        // let action = this.data.account_number + this.transaction
                                        // let value = action.split(" ");
                                        // this.showDeposit = true;
                                        this.$bvModal.hide(this.data.account_number + this.transaction);
                                    }
                                })
                                    .catch((error) => {

                                    });
                            })
                            .catch((error) => {

                                this.isSaving = false;

                                swal.fire({
                                    icon: "error",
                                    title: "Unable to process request",
                                    text: error.response?.data?.message,
                                    showCancelButton: false, // Show the "Cancel" button
                                    confirmButtonText: "OK", // Customize the text for the "OK" button
                                    buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                    customClass: {
                                        confirmButton:
                                            "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
                                    },
                                    // footer: '<a href="#">Why do I have this issue?</a>'
                                });

                            });
                    }
                });




            });
            // Hide the modal manually

        },
        accountSelected(accnt) {
            // console.log(accnt)
            // this.field.product = event?.product_type
            // this.field.member_name = event?.customer_name
            // this.field.telephone = event?.customer_mobile

            this.form.customer_name = accnt.customer_name;
            this.form.customer_mobile = accnt.customer_mobile;
            this.form.product_name = accnt.product_name;
        },
    }
}
</script>