<template>
   <div>
      <a title="View" class="btn btn-sm akkurate-warning text-white broder-0" v-b-modal="String(data.id + 'views')">
         <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span>
      </a>
      <b-modal :id="String(data.id + 'views')" size="xl" @shown="showView">
         <template #modal-title>
            <p class="m-0 fw-bold text-green">Loan View</p>
         </template>
         <div class="row px-3">
            <div class="card card-shape home-box p-0">
               <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
               <div class="card-header py-3">
                  <div class="row align-items-center">
                     <div class="col-6 col-md-6">
                        <h6 class="m-0 fw-bold text-green">Loan Account Preview</h6>
                     </div>
                     <div class="col-6 col-md-6 text-end">
                       <loan-form :institution="institution" :loan="loan"></loan-form>
                     </div>
                  </div>
               </div>
               <div class="card-body" style="background-color:#eaeff2">
                  <div class="row p-0">
                     <div class="col-3 col-md-3">
                        <img class="img-fluid img-visibility  w-100 h-100" :src="loan.account.customer.passport_pic_path">
                     </div>
                     <div class="col-3 col-md-3">
                        <div class="row">
                           <div class="col-12 col-md-12">
                              <p class="fw-bold">{{ getFullName }}</p>
                           </div>
                           <div class="col-12 col-md-12 small">
                              <div class="d-flex">
                                 <p class="mb-0 fw-bold me-3">Status:</p>
                                 <p class="mb-0">{{ loan.status }}</p>
                              </div>
                              <div class="d-flex">
                                 <p class="mb-0 fw-bold me-3">Tenure:</p>
                                 <p class="mb-0">{{ loan.tenor }}</p>
                              </div>
                              <div class="d-flex">
                                 <p class="mb-0 fw-bold me-3">Tenure Interval:</p>
                                 <p class="mb-0">{{ loan.tenor_interval }}</p>
                              </div>
                              <div class="d-flex">
                                 <p class="mb-0 fw-bold me-3">Interest Rate:</p>
                                 <p class="mb-0">{{ loan.interest_rate }} / {{ loan.tenor_interval }}</p>
                              </div>
                              <div class="d-flex">
                                 <p class="mb-0 fw-bold me-3">Interest Method:</p>
                                 <p class="mb-0">{{ loan.interest_method }}</p>
                              </div>
                              <div class="d-flex">
                                 <p class="mb-0 fw-bold me-3">Interest Expected:</p>
                                 <p class="mb-0">{{ Number(loan.expected_interest).toFixed(2) }}</p>
                              </div>
                              <div class="d-flex">
                                 <p class="mb-0 fw-bold me-3">Interest Balance:</p>
                                 <!-- <p class="mb-0">{{ loan.interest_balance - loan.interest_paid }}</p> -->
                                 <p class="mb-0">{{ Number(loan.interest_balance).toFixed(2) }}</p>
                              </div>
                              <div class="d-flex">
                                 <p class="mb-0 fw-bold me-3">Interest Paid:</p>
                                 <p class="mb-0">{{ Number(loan.interest_paid).toFixed(2) }}</p>
                              </div>
                              <div class="d-flex">
                                 <p class="mb-0 fw-bold me-3">Interest Due:</p>
                                 <p class="mb-0">{{ Number(loan.interest_due).toFixed(2) }}</p>
                              </div>
                              <div class="d-flex">
                                 <p class="mb-0 fw-bold me-3">Payment Due:</p>
                                 <p class="mb-0">{{ getDuePayment }}</p>
                              </div>
                           </div>

                        </div>
                     </div>
                     <div class="col-3 col-md-3 small">
                        <div class="row h-100">
                           <div class="col-md-12">
                              <div> <span class="fw-bold">Loan Type:</span> {{ loan?.loan_product.name }}</div>
                              <div> <span class="fw-bold">Disbursement Date:</span> {{
                                 loan?.interest_start_date }}
                              </div>
                              <div> <span class="fw-bold">Maturity Date:</span> {{ loan?.maturity_date }}</div>
                           </div>
                           <div class="col-md-12 mt-auto">
                              <div class="row">
                                 <div class="col-md-6 fw-bold">Principal:</div>
                                 <div class="col-md-6">{{ Number(loan.principal_amount).toFixed(2) }}</div>
                                 <div class="col-md-6 fw-bold">Principal Paid:</div>
                                 <div class="col-md-6">{{ Number(loan.principal_paid).toFixed(2) }}</div>
                                 <div class="col-md-6 fw-bold">Principal Bal:</div>
                                 <div class="col-md-6">{{ Number(loan.principal_balance).toFixed(2) }}</div>
                                 <div class="col-md-6 fw-bold">Principal Due:</div>
                                 <div class="col-md-6">{{ Number(loan.principal_due).toFixed(2) }}</div>

                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-3 col-md-3 small">
                        <div class="row h-100">
                           <div class="col-md-12">
                              <div> <span class="fw-bold">Loan Code:</span> {{ loan.loan_number }}</div>
                              <div> <span class="fw-bold">Last Payment Date:</span> {{ loan.last_payment }}</div>
                              <div> <span class="fw-bold">Delinquency:</span> {{ loan.delinquent }}</div>
                           </div>
                           <div class="col-md-12 mt-auto">
                              <div class="row">
                                 <div class="col-md-6 fw-bold">Current Value:</div>
                                 <div class="col-md-6">
                                    GHS {{ getCurrentValue }}
                                 </div>
                                 <div class="col-md-6 fw-bold">Loan Balance:</div>
                                 <div class="col-md-6">
                                    GHS {{ getLoanBalance }}
                                 </div>
                                 <div class="col-md-6 fw-bold">Share Bal:</div>
                                 <div class="col-md-6">
                                    GHS {{ Number(loan?.shares_account_balances).toFixed(2) }}
                                 </div>
                                 <div class="col-md-6 fw-bold">Saving Bal:</div>
                                 <div class="col-md-6">
                                    GHS {{ Number(loan?.savings_account_balances).toFixed(2) }}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
         <div class="row mt-5">
            <b-tabs content-class="mt-3" justified>
               <b-tab title="Collaterals" active>
                  <div class="container">
                     <div class="row">
                        <b-table striped hover :busy="isBusy" :items="col_items" :fields="col_fields">
                           <template #table-busy>
                              <div class="text-center text-danger my-2">
                                 <b-spinner class="align-middle"></b-spinner>
                                 <strong>Loading...</strong>
                              </div>
                           </template>
                           <template #cell(action)="data">
                              <div class="d-flex">
                                 <picture-view :data="data.item?.collateral_pictures"></picture-view>
                                 <document-view :data="data.item?.collateral_documents"></document-view>
                              </div>
                           </template>
                        </b-table>
                     </div>
                  </div>
               </b-tab>
               <b-tab title="Guarantors">
                  <div class="container">
                     <div class="row">
                        <b-table striped hover :busy="isBusy" :items="gua_items" :fields="gua_fields">
                           <template #table-busy>
                              <div class="text-center text-danger my-2">
                                 <b-spinner class="align-middle"></b-spinner>
                                 <strong>Loading...</strong>
                              </div>
                           </template>
                           <template #cell(name)="data">
                              <p>{{ data.item?.account?.customer?.first_name }} {{ data.item?.account?.customer?.middle
                                 }} {{ data.item?.account?.customer?.last_name }}</p>
                           </template>
                        </b-table>
                     </div>
                  </div>
               </b-tab>
               <b-tab title="Attachments">
                  <b-card-text>Tab contents 3</b-card-text>
               </b-tab>
               <b-tab title="Payment Schedules">
                  <div class="container">
                     <div class="row">
                        <b-table striped hover :busy="isBusy" :items="payment_items" :fields="payment_fields">
                           <template #table-busy>
                              <div class="text-center text-danger my-2">
                                 <b-spinner class="align-middle"></b-spinner>
                                 <strong>Loading...</strong>
                              </div>
                           </template>
                           <!-- <template #cell(name)="data">
                              <p>{{ data.item?.account?.customer?.first_name }} {{ data.item?.account?.customer?.middle }} {{ data.item?.account?.customer?.last_name }}</p>
                           </template> -->
                        </b-table>
                     </div>
                  </div>
               </b-tab>
            </b-tabs>
         </div>

         <template #modal-footer>
            <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="$bvModal.hide(String(data.id + 'views'))" type="submit"
                  class="akkurate-grey me-2 btn-size  float-right"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>
            </div>
         </template>

      </b-modal>
   </div>

</template>
<script>
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import PictureView from './modal/PictureView.vue'
import DocumentView from './modal/DocumentView.vue'
import LoanForm from "../application/LoanForm.vue";
export default {
   props: ['data','institution'],
   components: {
      BeatLoaderComponent,
      PictureView,
      DocumentView,
      LoanForm
   },
   data() {
      return {
         loan: {
            status: "",
            tenor: "",
            interest_rate: "",
            interest_method: "",
            expected_interest: "",
            interest_balance: "",
            interest_paid: "",
            interest_balance: "",
            loan_number: "",
            principal_amount: "",
            principal_paid: "",
            principal_balance: "",
            principal_due: "",
            loan_product: "",
            account: "",
            start_date: "",
            last_payment: "",
            delinquent: "",
            interest_due: "",
            account:{
               customer:{
                  passport_pic_path: ""
               }
            }
         },
         isLoading: false,
         isBusy: false,
         col_fields: [
            {
               key: 'id',
               label: '#',
            },
            {
               key: 'loan_number',
               label: 'Loan Number',
            },
            {
               key: 'product_name',
               label: 'Product Name',
            },
            {
               key: 'status',
               label: 'Status',
            },
            {
               key: 'address',
               label: 'Address',
            },
            {
               key: 'risk_level',
               label: 'Risk Level',
            },
            {
               key: 'action',
               label: 'Action',
            },
         ],
         col_items: [],
         gua_fields: [
            {
               key: 'id',
               label: '#',
            },
            {
               key: 'loan_number',
               label: 'Loan Number',
            },
            {
               key: 'amount',
               label: 'Amount',
            },
            {
               key: 'entry_date',
               label: 'Entry Date',
            },
            {
               key: 'account.account_number',
               label: 'Account Number',
            },
            {
               key: 'name',
               label: 'Guarantor(s) Name',
            },
         ],
         gua_items: [],
         payment_fields: [
            {
               key: 'due_date',
               label: 'Due Date',
            },
            {
               key: 'loan_number',
               label: 'Loan Number',
            },
            {
               key: 'starting_balance',
               label: 'Starting Balance',
            },
            {
               key: 'amount_due',
               label: 'Amount Due',
            },
            {
               key: 'principal_amount',
               label: 'Principal Amount',
            },
            {
               key: 'interest_amount',
               label: 'Interest Amount',
            },
            {
               key: 'ending_balance',
               label: 'Ending Balance',
            },
            {
               key: 'status',
               label: 'Status',
            },
         ],
         payment_items: [],
      }
   },
   computed: {
      getFullName() {
         const first_name = this.data.customer.first_name != undefined ? this.data.customer.first_name : "";
         const middle_name = this.data.customer.middle_name != undefined ? this.data.customer.middle_name : "";
         const last_name = this.data.customer.last_name != undefined ? this.data.customer.last_name : "";
         return first_name + ' ' + middle_name + ' ' + last_name;

      },
      getDuePayment() {
         const payment = Number(this.loan?.interest_due ?? 0) + Number(this.loan?.principal_due ?? 0);
         return payment.toFixed(2);
      },
      getCurrentValue() {
         const current = Number(this.loan?.principal_balance ?? 0) + Number(this.loan?.interest_due ?? 0);
         return current.toFixed(2)
      },
      getLoanBalance() {
         const balance = Number(this.loan?.interest_balance ?? 0) + Number(this.loan?.principal_balance ?? 0);
         return balance.toFixed(2)
      },
   },
   methods: {
      async getSingleLoan() {
         this.isLoading = true;
         this.isBusy = true,
            await ApiService.get(`/loans/${this.data.loan?.loan_number}`)
               .then((response) => {
                  this.isLoading = false;
                  this.isBusy = false,
                  this.loan = response.data.data;
                  this.col_items = response.data.data.collaterals;
                  this.gua_items = response.data.data.guarantors;
                  this.payment_items = response.data.data.payment_schedules;
               }).catch((error) => {
                  this.isLoading = false; s
                  this.isBusy = false,
                     console.log(error);
               });
      },
      showView() {
         console.log(this.data);
         this.getSingleLoan();
      }
   }
}
</script>
