import { render, staticRenderFns } from "./InterUnionDeposit.vue?vue&type=template&id=4ba45c8e"
import script from "./InterUnionDeposit.vue?vue&type=script&lang=js"
export * from "./InterUnionDeposit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports