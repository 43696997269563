<template>
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex mb-5">
                <b-button class="akkurate-green-btn me-3"><font-awesome-icon class="me-2" :icon="['fas', 'file-pdf']" />PDF</b-button>
                <b-button class="akkurate-green-btn"><font-awesome-icon class="me-2" :icon="['fas', 'book']" />Print</b-button>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <p>State of ______________________</p>
            </div>
            <div class="col-md-6 text-end">
                <img class="img-visibility" height="200px" src="@/assets/img/ggbccu_logo.png">
            </div>
            <div class="col-12 text-center border-bottom border-dark border-2">
                <h2 class="w-100">LOAN AGREEMENT</h2>
            </div>
            <div class="col-12 mt-5">
                <p>This Loan Agreement (this "Agreement"). is made as of this <span style="padding-left:4%;" class="border-bottom border-dark"></span> day of <span style="padding-left:20%;" class="border-bottom border-dark"></span>.20<span style="padding-left:10%;" class="border-bottom border-dark"></span>(the  "Effective Date") by and among/between;</p>
                <p><span class="fw-bold">Borrower(s):</span><span style="padding-left:40%;" class="border-bottom border-dark"></span>, located at <span style="padding-left:40%;" class="border-bottom border-dark"></span>[Address]("Borrower"), and</p>
                <p class="mt-3"><span class="fw-bold">Lender(s):</span><span style="padding-left:40%;" class="border-bottom border-dark"></span>, located at <span style="padding-left:42%;" class="border-bottom border-dark"></span>[Address]("Lender")</p>
                <p class="mt-4">The parties agree as follow</p>
                <p><span class="fw-bold">1. Loan Amount.</span> Lender agrees to loan Borrower the principal sum of $<span style="padding-left:15%;" class="border-bottom border-dark"></span>(the "Loan"), together with interest on the outstanding principal amount of the Loan (the "Principal Balance"), and in accordance with the terms set forth below.</p>
                <p><span class="fw-bold">2. Repayment of Loan.</span> (Check one)</p>
                <b-form-group>
                    <p><b-form-radio class="d-inline"  name="status" value="1"><span class="fw-bold">Single Payment.</span> </b-form-radio>The Loan together with accrused and unpaid interest and all other charges, costs and expenses, is due and payable (Check one) <b-form-radio class="d-inline"  name="status" value="1"></b-form-radio> on demand of the Lender <b-form-radio class="d-inline"  name="status" value="1"></b-form-radio> on or before <span style="padding-left:15%;" class="border-bottom border-dark"></span>, 20 <span style="padding-left:8%;" class="border-bottom border-dark"></span></p>
                    <p><b-form-radio class="d-inline"  name="status" value="1"><span class="fw-bold">Regular Payment.</span> </b-form-radio>The Loan together with accrused and unpaid interest and all other charges, costs and expenses, is due and payable on or before<br/> <span style="padding-left:15%;" class="border-bottom border-dark"></span>, 20 <span style="padding-left:8%;" class="border-bottom border-dark"></span> All payments under this Agreement are applied first to accrued interest and then to Principal Balance. The Loan shall be payable in installments equals  to $<span style="padding-left:10%;" class="border-bottom border-dark"></span>
                    . The first payment is due on <span style="padding-left:15%;" class="border-bottom border-dark"></span>, 20 <span style="padding-left:8%;" class="border-bottom border-dark"></span> and due thereafter in <br/> <span style="padding-left:8%;" class="border-bottom border-dark"></span>[Number of payments] equal consecutive: (Check one)</p>
                </b-form-group>
                <div class="ms-5 mt-3">
                    <p><b-form-radio class="d-inline"  name="status" value="1"></b-form-radio>Monthly installments. Each successive payment is due on the<span style="padding-left:15%;" class="border-bottom border-dark"></span>day of the month</p>
                    <p><b-form-radio class="d-inline"  name="status" value="1"></b-form-radio>Quarterly installments. Each successive payment is due on the<span style="padding-left:15%;" class="border-bottom border-dark"></span>day of the quarter</p>
                    <p><b-form-radio class="d-inline"  name="status" value="1"></b-form-radio>Semi-annual installments. Each successive payment is due on the<span style="padding-left:15%;" class="border-bottom border-dark"></span>day of the half-year</p>
                    <p><b-form-radio class="d-inline"  name="status" value="1"></b-form-radio>Annual installments. Each successive payment is due on the<span style="padding-left:15%;" class="border-bottom border-dark"></span>day of the year</p>
                </div>
                <p><span class="fw-bold">3. Security.</span> (Check one)</p>
                <p><b-form-radio class="d-inline"  name="status" value="1"></b-form-radio>The Loan is secured by collateral. Borrower agrees that until the Loan together with interest is paid in full, the Loan will be secured by <br/> <span style="padding-left:35%;" class="border-bottom border-dark"></span> and Borrower hereby grants to Lender a security interest in and to such property.</p>
            </div> 
        </div>
    </div>
    
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
    components: {
        Multiselect
    },
    data() {
        return {
        }
    }

}
</script>