<template>
    <div>
        <div class="d-flex align-items-center flex-column my-3">
            <!-- <p>Previews</p> -->
            <div v-if="previewData">
                <img class="imageCardPreviewWrapper" :src="previewData" alt="File Preview" />
            </div>
            <div class="akkurate-dark small">Choose File</div>
            <div class="border mt-2 d-flex align-items-center" style="width: 30%;">
                <label class="m-0 label akkurate-green-btn">
                    <input class="d-none" type="file" @change="handleFileChange" />
                    <span class="akkurate-small">Browse</span>
                </label>
                <span class="ms-2 flex-grow-1">{{ image_name }}</span>
                <b-button @click="UploadLogo" class="akkurate-green-btn">
                    <b-spinner v-if="spin_visible" small></b-spinner>
                    <i v-if="success_visible" class="fas fa-check loading"></i>
                    <i v-if="text_visible" class="fas fa-upload loading me-1 akkurate-small"></i>
                    <span class="akkurate-small">{{ upload }}</span>
                </b-button>
            </div>
            {{ getDefaultImg }}
            <!-- <div class="col-6 text-center">
               
            </div> -->

        </div>
        <div class="row">

            <div class="col-12 col-md-6">
                <label class="akkurate-dark" for="name">Name <star></star></label>
                <b-form-group id="name" label-for="name">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="form.name" name="name"
                        placeholder="Enter Name..." required></b-form-input>
                    <errors v-if="errors.name">
                        {{ errors.name[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6">
                <label class="akkurate-dark" for="motto">Motto <star></star></label>
                <b-form-group id="motto" label-for="motto">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="form.motto" name="motto"
                        placeholder="Enter Motto..." required></b-form-input>
                    <errors v-if="errors.motto">
                        {{ errors.motto[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6">
                <label class="akkurate-dark" for="alias">Alias <star></star></label>
                <b-form-group id="alias" label-for="alias">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="form.alias" name="alias"
                        placeholder="Enter Alias..." required></b-form-input>
                    <errors v-if="errors.alias">
                        {{ errors.alias[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6">
                <label class="akkurate-dark" for="region">Website url</label>
                <b-form-group id="website" label-for="website">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="form.url" name="url"
                        placeholder="Enter Website url..."></b-form-input>
                    <errors v-if="errors.url">
                        {{ errors.url[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6">
                <b-form-group label-for="address" class="mb-2">
                    <template #label>
                        <label class="akkurate-dark" for="address">Address <star></star></label>
                    </template>
                    <b-form-textarea class="mb-3 mt-2 field-container fields" id="address" v-model="form.address"
                        name="address" placeholder="Enter Address..." required></b-form-textarea>
                    <errors v-if="errors.address">
                        {{ errors.address[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6">
                <b-form-group label-for="postal" class="mb-2">
                    <template #label>
                        <label class="akkurate-dark" for="postal-address">Postal Address <star></star></label>
                    </template>
                    <b-form-textarea class="mb-3 mt-2 field-container fields" id="postal" v-model="form.postal"
                        name="postal" placeholder="Enter Postal Address..." required></b-form-textarea>
                    <errors v-if="errors.postal">
                        {{ errors.postal[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6">
                <label class="akkurate-dark" for="email">Email <star></star></label>
                <b-form-group id="email" label-for="email">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="email" v-model="form.email"
                        name="email" placeholder="Enter Email Address..." required></b-form-input>
                    <errors v-if="errors.email">
                        {{ errors.email[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6">
                <label class="akkurate-dark" for="telephone">Phone number <star></star></label>
                <b-form-group id="telephone" label-for="telephone">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="number" v-model="form.telephone"
                        name="telephone" placeholder="Enter Phone Number..." required></b-form-input>
                    <errors v-if="errors.telephone">
                        {{ errors.telephone[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6">
                <label class="akkurate-dark" for="mobile">Mobile Number <star></star></label>
                <b-form-group id="mobile" label-for="mobile">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="number" v-model="form.mobile"
                        name="mobile" placeholder="Enter Mobile Number..." required></b-form-input>
                    <errors v-if="errors.mobile">
                        {{ errors.mobile[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-8 col-md-4">
                <label class="akkurate-dark" for="currency">Currency <star></star></label>
                <b-form-group id="currency" label-for="currency">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="form.currency"
                        name="currency" placeholder="Please Enter Currency..." required></b-form-input>
                    <errors v-if="errors.currency">
                        {{ errors.currency[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-4 col-md-2">
                <label class="akkurate-dark" for="currency_code">Currency Code <star></star></label>
                <b-form-group id="currency_code" label-for="currency_code">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="form.currency_code"
                        name="currency_code" placeholder="Enter Currency Code..." required></b-form-input>
                    <errors v-if="errors.currency_code">
                        {{ errors.currency_code[0] }}
                    </errors>
                </b-form-group>
            </div>

            <div class="col-12 col-md-2">
                <b-form-group>
                    <template #label>
                        <label class="akkurate-dark" for="send_sms">Send Sms <star></star></label>
                    </template>
                    <b-form-radio v-model="form.send_sms" name="send_sms" value="1">Active</b-form-radio>
                    <b-form-radio v-model="form.send_sms" name="send_sms" value="0">Inactive</b-form-radio>
                    <errors v-if="errors.send_sms">
                        {{ errors.send_sms[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4">
                <label class="akkurate-dark small" for="bank_coasubhead_id">Bank COA</label>
                <b-form-group id="bank_coasubhead_id" label-for="bank_coasubhead_id" class="akkurate-dark">
                    <multiselect class="field-container mt-2 mb-3" v-model="form.bank_coasubhead" label="name"
                        name="bank_coasubhead_id" track_by="id" :custom-label="nameWithCode"
                        placeholder="Search or select a Coa Subhead" :options="form.coa_subheads_options"></multiselect>
                    <errors v-if="errors.bank_coasubhead_id">
                        {{ errors.bank_coasubhead_id[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6">
                <label class="akkurate-dark" for="accountant_name">Accountant name</label>
                <b-form-group id="accountant_name" label-for="accountant_name">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="form.accountant_name"
                        name="accountant_name" placeholder="Enter Accountant name..." required></b-form-input>
                    <errors v-if="errors.accountant_name">
                        {{ errors.accountant_name[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="accountant_phone">Accountant phone</label>
                <b-form-group id="accountant_phone" label-for="accountant_phone">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="form.accountant_phone"
                        name="accountant_phone" placeholder="Enter Accountant phone..." required></b-form-input>
                    <errors v-if="errors.accountant_phone">
                        {{ errors.accountant_phone[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="general_manager_name">General manager name</label>
                <b-form-group id="general_manager_name" label-for="general_manager_name">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                        v-model="form.general_manager_name" name="general_manager_name"
                        placeholder="Enter General manager name..." required></b-form-input>
                    <errors v-if="errors.general_manager_name">
                        {{ errors.general_manager_name[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="general_manager_phone">General manager phone</label>
                <b-form-group id="general_manager_phone" label-for="general_manager_phone">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                        v-model="form.general_manager_phone" name="general_manager_phone"
                        placeholder="Enter General manager phone..." required></b-form-input>
                    <errors v-if="errors.general_manager_phone">
                        {{ errors.general_manager_phone[0] }}
                    </errors>
                </b-form-group>
            </div>

            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="board_treasurer_name">Board Treasurer name</label>
                <b-form-group id="board_treasurer_name" label-for="board_treasurer_name">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                        v-model="form.board_treasurer_name" name="board_treasurer_name"
                        placeholder="Enter Board Treasurer name..." required></b-form-input>
                    <errors v-if="errors.board_treasurer_name">
                        {{ errors.board_treasurer_name[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="board_treasurer_phone">Board Treasurer phone</label>
                <b-form-group id="board_treasurer_phone" label-for="board_treasurer_name">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                        v-model="form.board_treasurer_phone" name="board_treasurer_phone"
                        placeholder="Enter Board Treasurer phone..." required></b-form-input>
                    <errors v-if="errors.board_treasurer_phone">
                        {{ errors.board_treasurer_phone[0] }}
                    </errors>
                </b-form-group>
            </div>

            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="board_chairman_name">Board Chairman name</label>
                <b-form-group id="board_chairman_name" label-for="board_chairman_name">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                        v-model="form.board_chairman_name" name="board_chairman_name"
                        placeholder="Enter Board chairman name..." required></b-form-input>
                    <errors v-if="errors.board_chairman_name">
                        {{ errors.board_chairman_name[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="board_chairman_phone">Board Chairman phone</label>
                <b-form-group id="board_chairman_phone" label-for="board_chairman_phone">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                        v-model="form.board_chairman_phone" name="board_chairman_phone"
                        placeholder="Enter Board chairman phone..." required></b-form-input>
                    <errors v-if="errors.board_chairman_phone">
                        {{ errors.board_chairman_phone[0] }}
                    </errors>
                </b-form-group>
            </div>

            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="loan_committee_name">Loan Committee chairman name</label>
                <b-form-group id="loan_committee_name" label-for="loan_committee_name">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                        v-model="form.loan_committee_name" name="loan_committee_name"
                        placeholder="Enter Loan committee name..." required></b-form-input>
                    <errors v-if="errors.loan_committee_name">
                        {{ errors.loan_committee_name[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="loan_committee_phone">Loan Committee chairman phone</label>
                <b-form-group id="loan_committee_phone" label-for="loan_committee_phone">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                        v-model="form.loan_committee_phone" name="loan_committee_phone"
                        placeholder="Enter Loan committee phone..." required></b-form-input>
                    <errors v-if="errors.loan_committee_phone">
                        {{ errors.loan_committee_phone[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="supervisory_committee_name">Supervisor committee chairman name</label>
                <b-form-group id="supervisory_committee_name" label-for="supervisory_committee_name">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                        v-model="form.supervisory_committee_name" name="supervisory_committee_name"
                        placeholder="Enter Supervisor committee name..." required></b-form-input>
                    <errors v-if="errors.supervisory_committee_name">
                        {{ errors.supervisory_committee_name[0] }}
                    </errors>
                </b-form-group>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <label class="akkurate-dark" for="supervisory_committee_phone">Supervisor committee chairman phone</label>
                <b-form-group id="supervisory_committee_phone" label-for="supervisory_committee_phone">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                        v-model="form.supervisory_committee_phone" name="supervisory_committee_phone"
                        placeholder="Enter Supervisor committee phone..." required></b-form-input>
                    <errors v-if="errors.supervisory_committee_phone">
                        {{ errors.supervisory_committee_phone[0] }}
                    </errors>
                </b-form-group>
            </div>



        </div>

    </div>



</template>
<script>
import config from '@/config'
import ApiService from '@/core/services/api.service';
import Multiselect from "vue-multiselect";
export default {
    props: ['form', 'errors'],
    components: {
        Multiselect
    },
    data() {
        return {
            config: config,
            previewData: "",
            picture_name: "",
            logo_name: "",
            selectedImage: "",
            upload: "Upload",
            spin_visible: false,
            success_visible: false,
            text_visible: true,
            image_name: "",


        };
    },
    computed: {
        getDefaultImg() {
            this.previewData = config.base_url + '/' + this.form?.logo;
            this.response = this.form?.logo;
        }
    },
    methods: {

        nameWithCode({ name, code }) {
            if (name) {
                return `${name} — ${code == undefined ? "" : code}`
            }
        },
        handleFileChange(event) {
            this.image_path = event.target.files[0];
            // console.log(this.image_path);
            if (this.image_path) {
                this.image_name = this.image_path.name;
                // this.selectedImage = this.image_path;
                // this.frontCardName =  this?.front_ghana_card.name;
                this.previewFile(this.image_path);
            }
        },
        previewFile(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.previewData = reader.result;
            };
        },
        async UploadLogo() {
            const formData = new FormData();
            formData.append('file', this.image_path);
            this.upload = "Processing..."
            this.visible = true;
            this.text_visible = false;
            this.spin_visible = true;
            await ApiService.post('/images/store', formData)
                .then((response) => {
                    // console.log(response.data);
                    this.upload = response.data.success;
                    this.upload = "Upload"
                    this.visible = false;
                    this.text_visible = true;
                    this.spin_visible = false;
                    this.previewData = null;
                    this.form.logo = response.data.path;

                }).catch((error) => {
                    this.front_upload_processing = false
                    console.log(error);
                })
        }
        // selectImage() {
        //     this.$refs.fileInput.click()
        // },

        // pickFile() {
        //     let input = this.$refs.fileInput
        //     let file = input.files
        //     if (file && file[0]) {
        //         let reader = new FileReader
        //         reader.onload = e => {
        //             this.previewImage = e.target.result
        //         }
        //         reader.readAsDataURL(file[0])
        //         this.$emit('input', file[0])
        //     }
        // },


    }
}
</script>
