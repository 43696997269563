<template>
    <form @submit.prevent="saveAttachment">
        <div class="row">
        <div class="col-12 col-md-7">
            <div class="row mb-3">
                <div class="col-6">
                    <b-form-group
                        label-for="name"
                        class="mb-3">
                        <template #label>
                            <label class="akkurate-dark small" for="name">Loan ID</label>
                        </template>
                        <b-form-input
                            id="name"
                            name="name"
                            class="mt-2 field-container fields"
                            v-model="getLoanNumber"
                            type="text"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group
                        label-for="name"
                        class="mb-3">
                        <template #label>
                            <label class="akkurate-dark small" for="name">Name</label>
                        </template>
                        <b-form-input
                            id="name"
                            name="name"
                            class="mt-2 field-container fields"
                            type="text"
                            v-model="form.name"
                        ></b-form-input>
                    </b-form-group>
                </div>
                
            </div>
        </div>
        <div class="col-12 col-md-3 mb-3 ">
           <label>Preview</label>
           <div v-if="selectedAttachment">
             <img class="imageCardPreviewWrapper" :src="previewAttcahmentUrl" alt="File Preview" />
          </div>
          <div class="border mt-2">
                <label class="m-0 label akkurate-green-btn">
                    <input class="d-none" type="file" @change="handleAttachment" />
                    <span class="akkurate-small">Browse</span>
                </label>
                <span class="ms-2">{{ attachment_name }}</span>
            </div>  
        </div>
        <div class="col-12 col-md-2 text-end">
            <button class="btn akkurate-green-btn text-white btn-size"><font-awesome-icon class="me-2" :icon="['fas', 'plus']" />Add</button>
        </div>
       
        <div class="col-md-12">
            <vue-good-table
                styleClass="vgt-table bordered table-dropdown striped"
                mode="remote"
                ref="attachTable"
                :columns="columns"
                :rows="rows">
            </vue-good-table>
        </div>
        <div class="text-end py-3">
            <SaveButton :isSaving="isSaving"/>
        </div>

       
    </div>

    </form>
   
</template>

<script>
import ApiService from "@/core/services/api.service";
import SaveButton from "@/views/main/components/SaveButton.vue";
import Multiselect from "vue-multiselect";
export default {
    props:['loan_number'],
    components: {
        Multiselect
    },
    data() {
        return {
            ...this.initialState(),
            isSaving: false,
            attachment_name: "",
            previewAttcahmentUrl: "",
            selectedAttachment: "",
            type: 'Car',
            type_options: ['Car', 'House', 'Salary'],
            columns: [
                {
                    label: 'S/N',
                    field: 'sn',
                },
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Item Location',
                    field: 'item_loc',
                },
            ],
            rows: [
                { sn: 'N/A', name: '', item_loc: '' },
                { sn: 'N/A', name: '', item_loc: '' },
                { sn: 'N/A', name: '', item_loc: '' },
            ]
        }
    },
    computed: {
        getLoanNumber() {
            this.form.loan_id = this.loan_number;
            return this.form.loan_id
        }
    },
    methods: {
        handleAttachment(event) {
            this.form.attachment = event.target.files[0];
            // console.log(this.form.frontGhanaCard);
            if (this.form.attachment) {
                this.selectedAttachment = this.form.attachment;
                this.attachment_name = this.form.attachment.name;
                this.previewAttachment(this.form.attachment);
            }
        },
        previewAttachment(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.previewAttcahmentUrl = reader.result;
            };
        },
        saveAttachment() {
            this.isSaving = true;
            this.$Progress.start();


            const data = new FormData();
            data.append('loan_id', this.form.loan_id);
            data.append('name', this.form.name);
            data.append('attachment', this.form.attachment);
            ApiService.post('/loans/loanAttachments', data)
                .then((response) => {
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    this.$Progress.finish();
                    this.form = this.initialState().form;
                }).catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        // this.form.errors = error.response.data.errors;
                        console.log(error)
                    }
                })

        },
        initialState() {
            return {
                form: {
                    loan_id: "",
                    name: "",
                    attachment: "",
                },
            }
        }

    }

}
</script>