<template>
    <!-- <div class="row">
        <div class="col-md-3 col-3 text-center bd-highlight">
            <logo></logo>
                <p v-if="title" class="mt-2 akkurate-green fw-bold mt-auto mb-0 bd-highlight small">{{ title }}</p>
        </div>
        <div class="bd-highlight col-md-9 col-9 text-end mt-auto">
            <p class="fw-bold text-uppercase mb-0">{{ institution?.name }}</p>
            <p class="fw-bold mb-2">{{ institution?.motto }}</p>
            <p class="mb-0 report-s-text">{{ institution?.postal }}</p>
            <p class="mb-0 report-s-text">{{ institution?.telephone }}</p>
            <p class="mb-2 report-s-text">{{ institution?.email }}</p>
            <div class="mt-4" v-if="current_date">
                <p class="fw-bold mb-0 text-end bd-highlight text-uppercase">{{ new Intl.DateTimeFormat('en-GB', {
                    day: 'numeric', month: 'long', year: 'numeric'
                }).format(Date.now()) }}</p>
            </div>
        </div>
    </div> -->
    <div class="d-flex justify-content-between">
        <div class="text-center">
            <logo></logo>
            <p v-if="title" class="mt-2 akkurate-green fw-bold mt-auto mb-0 bd-highlight small">{{ title }}</p>
        </div>
        <div class="text-end mt-auto">
            <p class="fw-bold text-uppercase mb-0">{{ institution?.name }}</p>
            <p class="fw-bold mb-2">{{ institution?.motto }}</p>
            <p class="mb-0 report-s-text">{{ institution?.postal }}</p>
            <p class="mb-0 report-s-text">{{ institution?.telephone }}</p>
            <p class="mb-2 report-s-text">{{ institution?.email }}</p>
            <div class="mt-4" v-if="current_date">
                <p class="fw-bold mb-0 text-end bd-highlight text-uppercase">{{ new Intl.DateTimeFormat('en-GB', {
                    day: 'numeric', month: 'long', year: 'numeric'
                }).format(Date.now()) }}</p>
            </div>

        </div>

    </div>
</template>

<script>
import Logo from '../Logo.vue'
export default {
    // props:['institution', 'title'],
    props: {
        institution: {
            required: false
        },
        title: {
            required: false
        },
        current_date: {
            required: false,
            default: true
        },
    },
    components: {
        Logo
    }
}
</script>