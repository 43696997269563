<template>
   <div>
      <b-modal :id="String(data.id + adjustment)" ref="modal" @ok="handleOk" @shown="modalShown" size="xl">
         <template #modal-title>
            <p class="akkurate-green fw-bold">Loan Adjustment</p>
         </template>
         <div class="row">
            <div class="col-12">
               <div class="row">
                  <form @submit.prevent="filterLoan">
                     <div class="row">
                        <!-- <div class="col-12 col-md-4">
                           <b-form-group
                           id="loan-re-date"
                           label-for="loan-re-date">
                           <multiselect class="mt-2 field-container" v-model="loan" :options="loan_options"></multiselect>
                           </b-form-group>
                        </div> -->
                        <div class="col-12 col-md-8">
                           <b-form-group id="loan_code" label-for="loan_code">
                              <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                 placeholder="Enter Loan Code" v-model="filter.loan_code" required></b-form-input>
                           </b-form-group>
                        </div>
                        <div class="mt-auto col">
                           <b-button type="submit" :disabled="isSearching"
                              class="akkurate-green-btn akkurate-auth-size w-100 mb-3"><font-awesome-icon class="me-2" :icon="['fas', 'search']" />{{ search }}</b-button>
                        </div>
                     </div>
                  </form>
               </div>
               <div class="card card-shape home-box">
                  <div class="card-header py-3">
                     <div class="row">
                        <div class="col-12 col-md-12">
                           <h6 class="m-0 fw-bold text-green">Loan Account Preview</h6>
                        </div>
                     </div>
                  </div>
                  <div class="card-body" style="background-color:#eaeff2">
                     <div class="row">
                        <div class="col-3 col-md-3">
                           <img class="img-fluid img-visibility  w-100 h-100" src="@/assets/img/user-avater.jpg">
                        </div>
                        <div class="col-3 col-md-3">
                           <div class="row">
                              <div class="col-12 col-md-12">
                                 <p class="fw-bold">{{ getFullNameAndID }}</p>
                              </div>
                              <div class="col-12 col-md-12 small">
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Status</p>
                                    <p class="mb-0">{{ form.status }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Tenure</p>
                                    <p class="mb-0">{{ form.tenor }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Tenure Interval:</p>
                                    <p class="mb-0">{{ form.tenor_interval }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Rate</p>
                                    <p class="mb-0">{{ form.interest_rate }} / {{ form.tenor_interval }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Method</p>
                                    <p class="mb-0">{{ form.interest_method }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Expected</p>
                                    <p class="mb-0">{{ Number(form.interest_balance).toFixed(2) }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Balance</p>
                                    <p class="mb-0">{{ Number(form.interest_balance).toFixed(2) }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Paid</p>
                                    <p class="mb-0">{{ Number(form.interest_paid).toFixed(2) }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Interest Due</p>
                                    <p class="mb-0">{{ Number(form.new_interest_due).toFixed(2) }}</p>
                                 </div>
                                 <div class="d-flex">
                                    <p class="mb-0 fw-bold me-3">Payment Due:</p>
                                    <p class="mb-0">{{ getDuePayment }}</p>
                                 </div>
                              </div>

                           </div>
                        </div>
                        <div class="col-3 col-md-3 small">
                           <div class="row h-100">
                              <div class="col-md-12">
                                 <div> <span class="fw-bold">Loan Type:</span> {{ form?.loan_product?.name }}</div>
                                 <div> <span class="fw-bold">Disbursement Date:</span> {{
                                    form?.interest_start_date
                                 }}</div>
                              </div>
                              <div class="col-md-12 mt-auto">
                                 <div class="row">
                                    <div class="col-md-6 fw-bold">Principal</div>
                                    <div class="col-md-6">{{ form.principal_amount }}</div>
                                    <div class="col-md-6 fw-bold">Principal Paid</div>
                                    <div class="col-md-6">{{ form.principal_paid }}</div>
                                    <div class="col-md-6 fw-bold">Principal Bal</div>
                                    <div class="col-md-6">{{ form.principal_balance }}</div>
                                    <div class="col-md-6 fw-bold">Principal Due</div>
                                    <div class="col-md-6">{{ form.principal_due }}</div>

                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-3 col-md-3 small">
                           <div class="row h-100">
                              <div class="col-md-12">
                                 <div> <span class="fw-bold">Loan Code:</span> {{ form.loan_number }}</div>
                                 <div> <span class="fw-bold">Last Payment Date:</span> {{ form.last_payment }}</div>
                                 <div> <span class="fw-bold">Delinquency:</span> {{ form.delinquent }}</div>
                              </div>
                              <div class="col-md-12 mt-auto">
                                 <div class="row">
                                    <div class="col-md-6 fw-bold">Current Value:</div>
                                    <div class="col-md-6">
                                       GHS {{ getCurrentValue }}
                                    </div>
                                    <div class="col-md-6 fw-bold">Loan Balance:</div>
                                    <div class="col-md-6">
                                       GHS {{ getLoanBalance }}
                                    </div>
                                    <div class="col-md-6 fw-bold">Share Bal:</div>
                                    <div class="col-md-6">
                                       GHS {{ Number(form?.shares_account_balances).toFixed(2) }}
                                    </div>
                                    <div class="col-md-6 fw-bold">Saving Bal:</div>
                                    <div class="col-md-6">
                                       GHS {{ Number(form?.savings_account_balances).toFixed(2) }}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="container mt-4">
                     <div class="row">
                        <div class="col-12 col-md-4">
                           <b-form-group label-for="interest_method" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="interest_method">Interest Method <star></star>
                                 </label>
                              </template>
                              <multiselect v-model="form.interest_method" :options="interest_method_options"
                                 :show-labels="false" @select="interestMethod" class="mt-2 field-container"
                                 placeholder="Pick a value">
                              </multiselect>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                           <b-form-group label-for="amount" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="amount">Amount <star></star></label>
                              </template>
                              <b-form-input id="amount" name="amount" class="mt-2 field-container fields" type="number"
                                 v-model="form.principal_amount"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4" v-show="hybrid">
                           <b-form-group label-for="interest_rate" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="interest_rate">Interest Rate % <star></star>
                                 </label>
                              </template>
                              <b-form-input id="interest_rate" name="interest_rate" class="mt-2 field-container fields"
                                 type="number" v-model="form.interest_rate"></b-form-input>
                           </b-form-group>
                        </div>


                        <div class="col-12 col-md-4" v-show="hybrid">
                           <b-form-group label-for="interest_rate_frequency" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="interest_rate_frequency">Interest Rate
                                    Frequency <star></star></label>
                              </template>
                              <multiselect v-model="form.interest_rate_frequency"
                                 :options="interest_rate_frequency_options" :show-labels="false"
                                 class="mt-2 field-container" placeholder="Pick a value">
                              </multiselect>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                           <b-form-group label-for="interest_start_date" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="interest_start_date">Interest Start Date <star>
                                    </star></label>
                              </template>
                              <b-form-input id="interest_start_date" name="interest_start_date"
                                 class="mt-2 field-container fields" type="date"
                                 v-model="form.interest_start_date"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4" v-show="straight_line">
                           <b-form-group label-for="expected_interest" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="expected_interest">Expected Interest <star>
                                    </star></label>
                              </template>
                              <b-form-input id="expected_interest" name="expected_interest"
                                 class="mt-2 field-container fields" type="number"
                                 v-model="form.expected_interest"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4" v-show="straight_line">
                           <b-form-group label-for="fixed_interest_rate" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="fixed_interest_rate">Fixed Interest Rate <star>
                                    </star></label>
                              </template>
                              <b-form-input id="fixed_interest_rate" name="fixed_interest_rate"
                                 class="mt-2 field-container fields" type="number"
                                 v-model="form.fixed_interest_rate"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4" v-show="straight_line">
                           <b-form-group label-for="fixed_interest_rate_frequency" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="fixed_interest_rate_frequency">Fixed Interest
                                    Rate Frequency <star></star></label>
                              </template>
                              <multiselect v-model="form.fixed_interest_rate_frequency"
                                 :options="fixed_interest_rate_frequency_options" :show-labels="false"
                                 class="mt-2 field-container" placeholder="Pick a value">
                              </multiselect>
                           </b-form-group>

                        </div>

                        <div class="col-12 col-md-4" v-show="straight_line">
                           <b-form-group label-for="fixed_interval" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="fixed_interval">Fixed Interval <star></star>
                                 </label>
                              </template>
                              <multiselect v-model="form.fixed_interval" :options="fixed_interval_options"
                                 :show-labels="false" class="mt-2 field-container" placeholder="Pick a value">
                              </multiselect>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4" v-show="straight_line">
                           <b-form-group label-for="fixed_duration" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="fixed_duration">Fixed Duration<star></star>
                                 </label>
                              </template>
                              <b-form-input id="fixed_duration" name="fixed_duration"
                                 class="mt-2 field-container fields" type="number"
                                 v-model="form.fixed_duration"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4" v-show="straight_line">
                           <b-form-group label-for="fixed_duration" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="variable_interest_rate">Variable Interest Rate
                                    <star></star>
                                 </label>
                              </template>
                              <b-form-input id="variable_interest_rate" name="variable_interest_rate"
                                 class="mt-2 field-container fields" type="number"
                                 v-model="form.variable_interest_rate"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4" v-show="straight_line">
                           <b-form-group label-for="variable_interest_rate_frequency" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="variable_interest_rate_frequency">Variable
                                    Interest Rate Frequency <star></star></label>
                              </template>
                              <multiselect v-model="form.variable_interest_rate_frequency"
                                 :options="variable_interest_rate_frequency_options" :show-labels="false"
                                 class="mt-2 field-container" placeholder="Pick a value">
                              </multiselect>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4" v-show="straight_line">
                           <b-form-group label-for="variable_interval" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="variable_interval">Variable Interval <star>
                                    </star></label>
                              </template>
                              <multiselect v-model="form.variable_interval" :options="variable_interval_options"
                                 :show-labels="false" class="mt-2 field-container" placeholder="Pick a value">
                              </multiselect>
                           </b-form-group>

                        </div>

                        <div class="col-12 col-md-4" v-show="straight_line">
                           <b-form-group label-for="variable_duration" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="variable_duration">Variable Duration <star>
                                    </star></label>
                              </template>
                              <b-form-input id="variable_duration" name="variable_duration"
                                 class="mt-2 field-container fields" type="number"
                                 v-model="form.variable_duration"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-3">
                           <label class="akkurate-dark small">Tenure <star></star></label>
                           <b-form-group>
                              <b-form-input id="tenor" name="tenor" class="mt-2 field-container fields" type="number"
                                 v-model="form.tenor"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-3">
                           <b-form-group label-for="tenor_interval" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="tenor_interval">Tenure Interval <star></star>
                                 </label>
                              </template>
                              <multiselect v-model="form.tenor_interval" :options="tenor_interval_options"
                                 :show-labels="false" class="mt-2 field-container" placeholder="Pick a value">
                              </multiselect>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                           <b-form-group label-for="repayment_cycle" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="repayment_cycle">Repayment Cycle <star></star>
                                 </label>
                              </template>
                              <multiselect v-model="form.repayment_cycle" :options="repayment_cycle_options"
                                 selectLabel="" deselectLabel="" :show-labels="false" class="mt-2 field-container"
                                 placeholder="Pick a value">
                              </multiselect>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                           <b-form-group label-for="maturity_date" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="maturity_date">Maturity Date</label>
                              </template>
                              <b-form-input id="maturity_date" name="maturity_date" v-model="form.maturity_date"
                                 class="mt-2 field-container fields" type="date"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                           <b-form-group label-for="penalty_rate" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="penalty_rate">Penalty Rate <star></star>
                                 </label>
                              </template>
                              <b-form-input id="penalty_rate" name="penalty_rate" class="mt-2 field-container fields"
                                 type="number" v-model="form.penalty_rate"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                           <b-form-group label-for="no_of_payment" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="reason">Reason <star></star></label>
                              </template>
                              <b-form-textarea class="mt-2 fields" id="reason" v-model="form.reason"
                                 placeholder="Enter Reason..."></b-form-textarea>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                           <b-form-group label-for="insurance" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="insurance">Insurance <star></star></label>
                              </template>
                              <b-form-input id="insurance" name="insurance" class="mt-2 field-container fields"
                                 type="number" v-model="form.insurance"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                           <b-form-group label-for="supervisor_approval_limit" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="supervisor_approval_limit">Supervisor Approval
                                    Limit <star></star></label>
                              </template>
                              <b-form-input id="supervisor_approval_limit" name="supervisor_approval_limit"
                                 class="mt-2 field-container fields" type="number"
                                 v-model="form.supervisor_approval_limit"></b-form-input>
                           </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                           <b-form-group label-for="branch_approval_limit" class="mb-3">
                              <template #label>
                                 <label class="akkurate-dark small" for="branch_approval_limit">Branch Approval Limit
                                    <star></star>
                                 </label>
                              </template>
                              <b-form-input id="branch_approval_limit" name="branch_approval_limit"
                                 class="mt-2 field-container fields" type="number"
                                 v-model="form.branch_approval_limit"></b-form-input>
                           </b-form-group>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
         <template #modal-footer="{ ok }">
            <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button :disabled="isSaving" @click="ok()" class="akkurate-green-btn me-2 float-right"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Commit
                  Adjustment</b-button>
               <!-- <b-button @click="ok()" type="submit" class="akkurate-blue me-2 float-right">Preview Adjustment</b-button>      -->

            </div>
         </template>
      </b-modal>

      <!-- <cash-repay :show-modal="showModal"></cash-repay> -->
   </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from "@/core/services/api.service"
export default {
   name: "Adjustment",
   props: ['data', 'institution', 'adjustment'],
   components: {
      Multiselect,
   },
   data() {
      return {
         ...this.initialState(),
         interest_method_options: ["straight-line", "reducing-balance", "hybrid"],
         interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
         fixed_interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
         fixed_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
         variable_interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
         variable_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
         tenor_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
         repayment_cycle_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
         filter: {
            loan_code: "",
         },
         search: "Search",
         straight_line: false,
         reducing_balance: false,
         hybrid: true,

         save: "Save",
         isSaving: false,
         isSearching: false,

         // fields: [
         //    { key: 'payment_terms', label: 'Payment Terms' },
         //    { key: 'after_payments', label: 'After Payment' },
         // ],
         // items: [
         //    { payment_terms: { title: 'Interest Paid', value: '1,543.00' }, after_payments: { title: 'Interest Outstanding', value: '1,543.00' } },
         //    { payment_terms: { title: 'Principal Paid', value: '457.00' }, after_payments: { title: 'Principal Outstanding', value: '1,543.00' } },
         //    { payment_terms: { title: 'Total Repayment', value: '2,000.00' }, after_payments: { title: 'Total Outstanding', value: '1,543.00' } },
         // ]
      }
   },
   computed: {
      getFullNameAndID() {
         const first_name = this.form?.account?.customer?.first_name != undefined ? this.form?.account?.customer?.first_name : "";
         const middle_name = this.form?.account?.customer?.middle_name != undefined ? this.form?.account?.customer?.middle_name : "";
         const last_name = this.form?.account?.customer?.last_name != undefined ? this.form?.account?.customer?.last_name : "";
         const code = this.form?.account?.customer?.code != undefined ? this.form?.account?.customer?.code : "";
         return first_name + ' ' + middle_name + ' ' + last_name + ' - ' + code;
      },
      getDuePayment() {
         const payment = Number(this.form?.interest_due ?? 0) + Number(this.form?.principal_due ?? 0);
         return payment.toFixed(2);
      },
      getCurrentValue() {
            const current = Number(this.form?.principal_balance ?? 0) + Number(this.form?.interest_due ?? 0);
            return current.toFixed(2)
        },
        getLoanBalance() {
            const balance = Number(this.form?.interest_balance ?? 0) + Number(this.form?.principal_balance ?? 0);
            return balance.toFixed(2)
        }
   },
   methods: {
      filterLoan() {
         this.search = "processing...";
         this.isSearching = true;
         ApiService.get(`/loans/${this.filter.loan_code}`)
            .then((response) => {
               this.search = "Search";
               this.isSearching = false;
               // console.log(response.data.data)
               this.form = response.data.data;
               if (response.data?.data?.interest_method == 'straight-line') {
                  this.straight_line = false;
                  this.hybrid = true;
                  // console.log("Straight Line");
               } else if (response.data?.data?.interest_method == 'reducing-balance') {
                  this.straight_line = true;
                  this.hybrid = true;
               } else if (response.data?.data?.interest_method == 'hybrid') {
                  this.straight_line = true;
                  this.hybrid = false;
               }
               // if (response.data.data.status == "PENDING") {
               //    this.isPending = false
               // } else {
               //    this.isPending = true
               // }

               //   this.getLoanEntiries(this.filter.loan_code);
               // console.log(this.items[0].after_payments.value = this);
               // this.items[0].payment_terms.value = this.loan.interest_paid
               // this.items[1].payment_terms.value = this.loan.principal_paid
               // this.items[2].payment_terms.value = this.loan.principal_amount
               //   console.log();
            }).catch((error) => {
               this.search = "Search";
               this.isSearching = false;
               if (error.response.status == 404) {
                  swal.fire({
                     icon: "error",
                     title: error.response.statusText,
                     text: "Something went wrong!",
                     showCancelButton: false, // Show the "Cancel" button
                     confirmButtonText: 'OK', // Customize the text for the "OK" button
                     buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                     customClass: {
                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                     }
                     // footer: '<a href="#">Why do I have this issue?</a>'
                  });
               }
            })
      },

      interestMethod(value) {
         // console.log(value);
         switch (value) {
            case 'straight-line':
               this.straight_line = false;
               this.hybrid = true;
               break;
            case 'reducing-balance':
               this.straight_line = true;
               this.hybrid = true;
               break;
            case 'hybrid':
               this.straight_line = true;
               this.hybrid = false;
               break;
         }
      },
      handleOk(bvModalEvent) {
         // Prevent modal from closing
         bvModalEvent.preventDefault();
         // Trigger submit handler
         this.handleSubmit()
      },
      handleSubmit() {
         // Exit when the form isn't valid
         this.$validator.validateAll().then(result => {
            if (!result) {
               // console.log(result);
               return;
            }
            this.storeLoanAdjust();

         });
      },
      async storeLoanAdjust() {
         // console.log("Here");
         this.isSaving = true;
         this.$Progress.start();
         this.form.loan_number = this.filter.loan_code;
         await ApiService.post('/loans/loanAdjustment', this.form)
            .then((response) => {
               this.isLoadingSaving = false;
               this.$Progress.finish();
               toast.fire({
                  icon: "success",
                  title: response.data.message,
               });
               this.isSaving = false;
               // this.form.reset();
               this.form = this.initialState().form;
            }).catch((error) => {
               this.isSaving = false;
               this.$Progress.fail();
               if (error.response.data.errors) {
                  this.form.errors = error.response.data.errors;
               }
            })
      },
      initialState() {
         return {
            form: {
               loan_number: "",
               principal_amount: "",
               interest_method: "", // ['straight-line', 'reducing-balance', 'hybrid']
               interest_rate: "",
               interest_rate_frequency: "Monthly", // ['Daily', 'Weekly', 'Monthly', 'Annually']
               interest_start_date: "2023-11-12",
               expected_interest: "",
               fixed_interest_rate: "",
               fixed_interest_rate_frequency: "", //['Daily', 'Weekly', 'Monthly', 'Annually']
               fixed_interval: "", //['Days', 'Weeks', 'Months', 'Years']
               fixed_duration: "",
               variable_interest_rate: "",
               variable_interest_rate_frequency: "", //['Daily', 'Weekly', 'Monthly', 'Annually']
               variable_interval: "", //['Days', 'Weeks', 'Months', 'Years']
               variable_duration: "",
               tenor: "", // integer 
               tenor_interval: "", //['Days', 'Weeks', 'Months', 'Years']
               repayment_cycle: "", // ['Daily', 'Weekly', 'Monthly', 'Annually']
               maturity_date: "",
               penalty_rate: "", // Penalty fee
               reason: "",
               recovery_officer: 2, // "User ID"
               insurance: 0 // integer
            }

         }
      },
      modalShown() {
         if (this.data.id != 'app_adjustment') {
            this.filter.loan_code = this.data?.loan?.loan_number;
            this.filterLoan();
         }
      }


   }
}
</script>