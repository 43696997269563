<template>
    <form @submit.prevent="storeGaurantor">
      <div class="row">
        <div class="col-md-3">
            <b-form-group
                label-for="loan_id"
                class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="account_number">Loan ID</label>
                </template>
                <b-form-input
                    id="loan_id"
                    name="loan_id"
                    class="mt-2 field-container fields"
                    type="text"
                    v-model="getLoanNumber"
                    disabled
                ></b-form-input>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group
                label-for="account_number"
                class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="account_number">Account Number</label>
                </template>
                <b-form-input
                    id="account_number"
                    name="account_number"
                    class="mt-2 field-container fields"
                    type="text"
                    v-model="account_info.account_number"
                    
                ></b-form-input>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group
                label-for="fullname"
                class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="fullname">Fullname</label>
                </template>
                <b-form-input
                    id="fullname"
                    name="fullname"
                    class="mt-2 field-container fields"
                    type="text"
                    v-model="getFullName"
                    disabled
                ></b-form-input>
            </b-form-group>
        </div>
    <!-- <div class="col-md-4">
            <b-form-group
                label-for="gender"
                class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="gender">Product</label>
                </template>
                <multiselect
                    v-model="product" 
                    deselect-label="Can't remove this value"
                    track-by="name" placeholder="Select one"
                    :options="product_options" 
                    selectLabel=""
deselectLabel="" 
                    :allow-empty="false">
                </multiselect>
            </b-form-group>
    </div> -->

    <!-- <div class="col-md-4">
            <b-form-group
                label-for="balance"
                class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="balance">Balance</label>
                </template>
                <b-form-input
                    id="balance"
                    name="balance"
                    class="mt-2 field-container fields"
                    type="text"
                    disabled
                ></b-form-input>
            </b-form-group>
    </div> -->

    <div class="col-md-3">
            <b-form-group
                label-for="gua_amt"
                class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="gua_amt">Guaranteed Amount</label>
                </template>
                <b-form-input
                    id="gua_amt"
                    name="gua_amt"
                    class="mt-2 field-container fields"
                    type="text"
                    v-model="form.amount"
                ></b-form-input>
            </b-form-group>
    </div>

    <!-- <div class="col-md-4">
            <b-form-group
                label-for="entry_date"
                class="mb-3">
                <template #label>
                    <label class="akkurate-dark small" for="entry_date">Entry Date</label>
                </template>
                <b-form-input
                    id="entry_date"
                    name="entry_date"
                    class="mt-2 field-container fields"
                    type="text"
                    
                ></b-form-input>
            </b-form-group>
    </div> -->

    <div class="text-end py-3">
    <button type="submit" class="btn akkurate-green-btn text-white btn-size my-2 me-2"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Post Application</button>
    </div>
    </div>
    </form>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
export default {
    props: ['account_info','loan_number'],
    components: {
        Multiselect
    },
    data() {
        return {
            ...this.initialState(),
            full_name: "",
        }
    },
    computed: {
        getFullName() {
            const first_name = this.account_info.customer?.first_name != undefined ? this.account_info.customer?.first_name : "";
            const middle_name = this.account_info.customer?.middle_name != undefined ? this.account_info.customer?.middle_name : "";
            const last_name = this.account_info.customer?.last_name != undefined ? this.account_info.customer?.last_name : "";
            return first_name + ' ' + middle_name + ' ' + last_name;

        },
        getLoanNumber() {
            this.form.loan_id = this.loan_number;
            // console.log(this.form.loan_id);
            return this.form.loan_id
        }
    },
    methods: {
        storeGaurantor() {
            ApiService.post('/loans/loanGuarantors', this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();

                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;

                }).catch((response) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        // this.form.errors = error.response.data.errors;
                        console.log(error)
                    }
                })
        },
        initialState() {
            return {
                form: {
                    loan_id: "",
                    account_number: "",
                    amount: "",
                    entry_date: Date.now()
                }
            }
        }
    }

}
</script>