<template>
    <div id="wrapper">
            <SidebarView :member-import="true" :members="true" :member-visible="true" />
            <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
              <TopbarView />
              <div class="container">
                <div class="row">
                  <div class="col-12">
                      <!-- begin loader -->
                      <beat-loader-component
                      v-if="isLoading"
                      :isLoading="isLoading"
                      ></beat-loader-component>
                      <!-- end loader -->
                  </div>
                  <div class="col-12">
                    <div class="card card-shape home-box">
                      <div class="card-header py-3 d-flex flex-row align-items-center">
                        <h6 class="m-0 fw-bold text-green">Import Menu</h6>
                      </div>
                      <div class="card-body">
                        <form class="form" method="POST" @submit.prevent="">
                            <b-row>
                                <b-col md="6" class="d-md-flex justify-content-md-end">
                                    <b-form-group
                                    label-for="account_type"
                                    invalid-feedback="Account Type is required"
                                    class="mb-3">
                                    <template #label>
                                    <label class="akkurate-dark" for="account_type">Files <star></star></label>
                                    </template>
                                    <b-form-file v-model="file2" class="mt-3 field-container fields" plain></b-form-file>
                                    </b-form-group>
                                   <div class="d-md-flex justify-content-center">

                                     <!-- <b-form-file v-model="file2" class="mt-3 field-container fields" plain></b-form-file>
                                     <button type="submit" class="btn akkurate-green-btn text-white my-2">Download Sample</button> -->
                                   </div>
                                </b-col>
                                <b-col md="6" class="d-md-flex justify-content-md-start">
                                    <b-form-group
                                    label-for="account_type"
                                    invalid-feedback="Account Type is required"
                                    class="mb-3 float-left">
                                    <template #label>
                                    <label class="akkurate-dark" for="account_type">Sample File <star></star></label>
                                    </template>
                                    <button type="submit" block  class="btn akkurate-green-btn text-white my-2"><font-awesome-icon class="me-2" :icon="['fas', 'download']" />Download Sample</button>
                                    </b-form-group>
                                   <div class="d-md-flex justify-content-center">

                                    
                                   </div>
                                </b-col>
                            </b-row>
                           
                          <!-- <users-form :roles="roles" :form="form" :add_or_edit="add_or_edit"></users-form> -->
                          <hr />
                          <div class="text-center py-3">
                            <SaveButton :isSaving="isSaving"/>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
       
            </div>
          <Footer></Footer>
          </div>
            <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
        </div>
    
    </template>
    
<script>

import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

// import UsersForm from "./../forms/UsersForm.vue";
import ApiService from "@/core/services/api.service";

import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue'
import SaveButton from "@/views/main/components/SaveButton.vue";

export default {
    components: {
        name: 'ImportFiles',
        BeatLoaderComponent,
        // UsersForm,
        SidebarView,
        TopbarView,
        Footer,
        SaveButton
    },
    mounted() {
        let token = localStorage.getItem('token');
        if (!token) {
            this.$router.push({ path: '/login' });
        }
        this.getUserOptions();
    },
    // data() {
    //   return 
    //   {
    //         // this.initialState(),
    //         roles: [],
    //   };
    // },
    data() {
        return {
            //   ...this.initialState(),
            //   roles: []
        };
    },
    //   methods: {
    //     getUserOptions() {
    //       this.$Progress.start();
    //       this.isLoading = true;
    //       // axios
    //       //   .get("roles/dropdown")
    //       ApiService.get("roles/dropdown")
    //         .then((response) => {
    //           // console.log(response.data);
    //           this.roles = response.data.roles;
    //           this.isLoading = false;
    //           this.$Progress.finish();
    //           this.isLoading = false;
    //         })
    //         .catch((error) => {
    //           this.isLoading = false;
    //           this.$Progress.fail();
    //         });
    //     },
    //     storeUser() {
    //       this.isSaving = true;
    //       this.$Progress.start();

    //       // if(this.form.selected_roles)
    //       // {
    //       //   this.form.role_name = this.form.selected_roles.name
    //       // }
    //       // else
    //       // {
    //       //   this.form.role_name = "";
    //       // }

    //       // this.form
    //       //   .post("/users/store")
    //       ApiService.post("users/store", this.form)
    //         .then((response) => {
    //           this.isLoadingSaving = false;
    //           this.$Progress.finish();

    //           toast.fire({
    //             icon: "success",
    //             title: response.data.message,
    //           });
    //           this.isSaving = false;
    //           // this.form.reset();
    //           this.form = this.initialState().form;
    //         })
    //         .catch((error) => {
    //           this.isSaving = false;
    //           this.$Progress.fail();
    //           if (error.response.data.errors) {
    //             this.form.errors = error.response.data.errors;
    //           }
    //         });
    //     },
    //     initialState() {
    //       return {
    //         form: {
    //           first_name: "",
    //           surname: "",
    //           email: "",
    //           phone_number: "",
    //           username: "",
    //           address: "",
    //           selected_roles: [],
    //           password: "",
    //           password_confirmation: "",
    //           status: 1,
    //           role_name: "",
    //           errors: []
    //         },
    //         isLoading: true,

    //         isSaving: false,
    //         add_or_edit: 'add',
    //       };
    //     },
    //   },
};
</script>
    
    <!-- New step!
         Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
    
    