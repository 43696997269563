<template>
  <div id="wrapper">
    <SidebarView :accounts-visible="true" :all-account-types="true" accounts="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-row align-items-center justify-content-between">


              </div>
              <div class="card card-shape home-box">
                <div class="card-header d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 fw-bold text-green">All Account Types</h6>
                  <div class="d-flex align-items-center">
                    <add-account-type @account-type-saved="accountTypeSaved"></add-account-type>
                    <b-link @click="exportToExcel" class="fw-semibold float-end ms-3 akkurate-green">
                      <font-awesome-icon :icon="['fas', 'book']" /></b-link>
                  </div>

                </div>
                <div class="card-body">
                  <!-- <div class="row">
                    <div class="col-md-6">
                       Modal Start
                        <add-account-type @account-type-saved="accountTypeSaved"></add-account-type>
                        Modal End
                    </div>
                    <div class="col-md-6">
                       Modal Start
                       <b-button class="fw-semibold float-end btn-sm me-5" v-on:click="exportToExcel" type="button" variant="primary">
                       
                       </b-button>
                       <b-link @click="exportToExcel" class="fw-semibold float-end me-3 akkurate-green"> <font-awesome-icon :icon="['fas', 'book']" /></b-link>
                        Modal End
                    </div>
                  </div> -->
                  <vue-good-table styleClass="vgt-table bordered striped" mode="remote" @on-page-change="onPageChange"
                    @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :isLoading.sync="isLoading"
                    :pagination-options="{
                      enabled: true,
                      perPage: serverParams.perPage,
                    }" :rows="rows" :columns="columns">
                    <!-- Add your custom delete button column -->
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'increase_by'">
                        <div>Debit</div>
                      </span>
                      <span v-else-if="props.column.field == 'decrease_by'">
                        <div>Credit</div>
                      </span>
                      <span v-else-if="props.column.field == 'action'">
                        <!-- <a title="View" class="btn btn-sm akkurate-warning text-white" @click="confirmDeleteUser(props.row)">
                            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
                        </a> -->
                        <a title="Delete" class="btn btn-sm akkurate-danger-btn text-white"
                          @click="confirmDeleteUser(props.row)">
                          <span class="akkurate-small">
                            <font-awesome-icon :icon="['fas', 'trash']" /></span>
                        </a>
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>

<script>
// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import ApiService from "@/core/services/api.service";
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
// import config from "@/config.js";
// import Vue from "vue";
import AddAccountType from "../forms/AddAccountType.vue";

import * as XLSX from "xlsx";
import FileSaver from "file-saver";

export default {
  components: {
    // BeatLoaderComponent,
    SidebarView,
    TopbarView,
    Footer,
    AddAccountType,
  },
  mounted() {
    let token = localStorage.getItem("token");
    if (!token) {
      this.$router.push({ path: "/login" });
    }
    this.loadItems();
  },
  data() {
    return {
      searchFilter: "",
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
          type: "text",
        },
        {
          label: "Number From",
          field: "number_from",
          type: "text",
        },
        {
          label: "Number To",
          field: "number_to",
          type: "text",
        },
        {
          label: "Financial Type",
          field: "fin_statement",
          type: "text",
        },
        {
          label: "Increase By",
          field: "increase_by",
          type: "text",
        },
        {
          label: "Decrease By",
          field: "decrease_by",
          type: "text",
        },
        {
          label: "Created At",
          field: "created_at",
          type: "text",
        },
        {
          label: "Action",
          field: "action",

          html: true,
          //   formatFn: this.renderActions,
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 20,
      },
    };
  },

  methods: {
    accountTypeSaved() {
      this.onPageChange(1);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onSearch(event) {
      // console.log(event)
      this.loadItems(event.searchTerm);
    },

    async loadItems(searchFilter = null) {
      //   getFromServer(this.serverParams).then(response => {
      //      this.totalRecords = response.totalRecords;
      //      this.rows = response.rows;
      //   });
      this.isLoading = true;
      await ApiService.post(
        "/finance/chart-of-accounts/account-types/paginate",
        {
          page: this.serverParams.page,
          per_page: this.serverParams.perPage,
          filter: searchFilter,
        }
      )
        .then((response) => {
          // console.log(response.data.data)
          this.rows = response.data.data;
          // this.per_page = response.data.meta.per_page;
          // console.log(response.data.meta.per_page)
          this.totalRecords = response.data.meta.total;

          this.totalRecords = response.data.meta.total;
        })
        .catch((error) => {
          // console.log(error);
          this.isLoading = false;
        })
        .finally((resp) => {
          // this.isLoading = false
          this.isLoading = false;
        });
    },
    exportToExcel() {
      // console.log("Here")
      const workbook = XLSX.utils.book_new();

      // Get the table data
      const tableData = this.$refs.allAccountTable.filteredRows[0].children;

      // Convert the table data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate the Excel file buffer
      const excelBuffer = XLSX.write(workbook, {
        type: "array",
        bookType: "xlsx",
      });

      // Save the Excel file
      FileSaver.saveAs(
        new Blob([excelBuffer], { type: "application/octet-stream" }),
        "table_data.xlsx"
      );
    },
  },
};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
